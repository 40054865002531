import { ReactElement, useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { graphql } from "gatsby";
import { useDependency } from "../../../contexts/DependencyContext";
import { showModal, closeLatestModal } from "../../../hooks/UseModal";
import { SupportRequestsService } from "../../../services/http/SupportRequestsService";
import { TelemetryService } from "../../../services/telemetry/TelemetryService";
import Helmet from "../../../components/Helmet";
import FilesUploader from "../../../components/Shared/FilesUploader";
import { AcceptModal } from "../../../components/Shared/Modals/AcceptModal";
import { LoadingModal } from "../../../components/Shared/Modals/LoadingModal";
import Captcha, { ICaptchaHandle } from "../../../components/Shared/Captcha";
import SupportTypeSelect from "../../../components/Support/SupportTypeSelect";
import SupportProductSelect from "../../../components/Support/SupportProductSelect";
import removeWhiteSpaces from "../../../shared/RemoveWhiteSpaces";
import { formImages } from "../../../components/Icons";
import * as styles from "./Technical.module.scss";

interface ITechnicalProps {
    data: GatsbyTypes.TechnicalSupportPageQuery;
}

export default function TechnicalSupportPage({ data }: ITechnicalProps): ReactElement {
    const [selectedSupportType, setSelectedSupportType] = useState<GatsbyTypes.strapi_SupportTypes | undefined>();
    const [formData, setFormData] = useState<{ [name: string]: string }>({});
    const [specificFormData, setSpecificFormData] = useState<{ [name: string]: string }>({});
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [files, setFiles] = useState<File[]>([]);
    const notification = data.strapi.technicalSupportPage?.notification;
    const maxFiles = 10;

    const {
        register,
        reset,
        formState: { errors },
        handleSubmit,
        setValue,
    } = useForm({ reValidateMode: "onBlur" });
    const captchaRef = useRef<ICaptchaHandle>(null);
    const supportRequestsService = useDependency(SupportRequestsService);
    const telemetryService = useDependency(TelemetryService);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search.toLowerCase());

        if (urlParams.has("supporttype")) {
            const matchingSupportType = data.strapi.supportTypes?.find(x => urlParams.get("supporttype") === removeWhiteSpaces(x?.name));

            if (matchingSupportType) {
                setSelectedSupportType(matchingSupportType as GatsbyTypes.strapi_SupportTypes);

                if (urlParams.has("product")) {
                    const matchingProductId = data.strapi.products?.find(x => urlParams.get("product") === removeWhiteSpaces(x?.name))?.id;
                    if (matchingProductId) setFormData({ ...formData, product: matchingProductId });
                }
            }
        }
    }, []);

    function removeFile(file: File) {
        const newFiles = [...files];
        newFiles.splice(newFiles.indexOf(file), 1);
        setFiles(newFiles);
    }

    async function resetForm() {
        setSelectedSupportType(undefined);
        setFiles([]);
        setFormData({});
        setSpecificFormData({});
        setTermsAccepted(false);
        reset({});
        captchaRef?.current?.reset();
        await showModal(AcceptModal, { title: notification?.successTitle ?? "", content: notification?.successDescription ?? "", buttonText: notification?.acceptButtonText ?? "", error: false });
    }

    function handleSupportTypeChange(supportType: GatsbyTypes.strapi_SupportTypes | undefined) {
        const newSpecificFormData: { [name: string]: string } = {};
        supportType?.fields?.forEach(x => {
            if (x) {
                newSpecificFormData[x?.name] = "";
                setValue(x?.name as `${string}`, "");
            }
        });
        setSpecificFormData(newSpecificFormData);
        setSelectedSupportType(supportType);
    }

    function handleValueChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, isSpecific: boolean = false) {
        if (isSpecific) setSpecificFormData({ ...specificFormData, [event.target.name]: event.target.value });
        else setFormData({ ...formData, [event.target.name]: event.target.value });
    }

    async function handleTechnicalSubmit() {
        showModal(LoadingModal, { content: notification?.loadingText ?? "" });

        try {
            const sendData = { ...formData, ...specificFormData, ...{ support_type: selectedSupportType?.id } };
            await supportRequestsService.saveRequestAsync(sendData as GatsbyTypes.strapi_SupportRequestInput, files);
            closeLatestModal();
            await resetForm();
        } catch (error) {
            closeLatestModal();
            telemetryService.error(error);
            await showModal(AcceptModal, { title: notification?.errorTitle ?? "", content: notification?.errorDescription ?? "", buttonText: notification?.acceptButtonText ?? "", error: true });
        }
    }

    return (
        <>
            <Helmet title={"Technical support"} description={""} />

            <section className="mainBox">
                <div className={styles.technicalSupport}>
                    <div className={styles.headerTechnicalSupport}>
                        <div className={styles.title}>
                            <h1>{data.strapi.technicalSupportPage?.base?.heading}</h1>
                            <div dangerouslySetInnerHTML={{ __html: data.strapi.technicalSupportPage?.base?.paragraph ?? "" }}></div>
                        </div>
                        <div className={styles.headerImage}>{formImages.technicalSupport}</div>
                    </div>

                    <div className={styles.technicalSupportWrapper}>
                        <div className={styles.inner}>
                            {!selectedSupportType && (
                                <>
                                    <div dangerouslySetInnerHTML={{ __html: data.strapi.technicalSupportPage?.instructions ?? "" }} className={styles.instructions}></div>
                                </>
                            )}

                            <div className={styles.mainSelect}>
                                <SupportTypeSelect
                                    selectedValue={selectedSupportType?.id ?? ""}
                                    onSelectionChange={handleSupportTypeChange}
                                    types={data.strapi.supportTypes as GatsbyTypes.strapi_SupportTypes[]}
                                    placeholder={data.strapi.technicalSupportPage?.typePlaceholder ?? ""}
                                />
                            </div>

                            {selectedSupportType && (
                                <form onSubmit={handleSubmit(handleTechnicalSubmit)} noValidate>
                                    <div>
                                        <div className={styles.inputGroup}>
                                            <input
                                                type="text"
                                                maxLength={255}
                                                {...register("userEmail", {
                                                    required: { value: true, message: data.strapi.technicalSupportPage?.baseForm?.requiredFieldText ?? "" },
                                                    pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                        message: data.strapi.technicalSupportPage?.baseForm?.invalidEmailFieldText ?? "",
                                                    },
                                                })}
                                                className={errors.userEmail ? styles.invalidInputForm : ""}
                                                value={formData.userEmail ?? ""}
                                                onChange={handleValueChange}
                                            />
                                            <label className={`${styles.inputLabel} ${errors.userEmail && styles.errorInputFormatColor}`} htmlFor="email">
                                                {data.strapi.technicalSupportPage?.baseForm?.emailLabel}
                                            </label>
                                            {errors.userEmail && <span className={styles.errorInputFormatColor}>{errors.userEmail.message}</span>}
                                        </div>
                                        <SupportProductSelect
                                            selectedValue={formData.product}
                                            supportType={selectedSupportType}
                                            register={register("supportProduct", { required: { value: true, message: data.strapi.technicalSupportPage?.baseForm?.requiredFieldText ?? "" } })}
                                            onSelectionChange={product => setFormData({ ...formData, product })}
                                            products={data.strapi.products as GatsbyTypes.strapi_Product[]}
                                            placeholder={data.strapi.technicalSupportPage?.productPlaceholder ?? ""}
                                            customClass={errors.supportProduct ? styles.invalidInputForm : ""}
                                        />
                                        {errors.supportProduct && <span className={styles.errorInputFormatColor}>{errors.supportProduct.message}</span>}

                                        {selectedSupportType.fields?.map(
                                            x =>
                                                x && (
                                                    <div key={x.id} className={styles.inputGroup}>
                                                        <input
                                                            type="text"
                                                            {...register(x.name as ``, { required: { value: true, message: data.strapi.technicalSupportPage?.baseForm?.requiredFieldText ?? "" } })}
                                                            className={errors[x.name] ? styles.invalidInputForm : ""}
                                                            maxLength={255}
                                                            title={x.tooltip}
                                                            value={specificFormData[x.name]}
                                                            onChange={e => handleValueChange(e, true)}
                                                        />
                                                        <label className={`${styles.inputLabel} ${errors[x.name] && styles.errorInputFormatColor}`} htmlFor={x.name}>
                                                            {x.label}
                                                        </label>
                                                        {errors[x.name] && <span className={styles.errorInputFormatColor}>{errors[x.name].message}</span>}
                                                    </div>
                                                )
                                        )}
                                        <div className={styles.inputGroup}>
                                            <input
                                                maxLength={255}
                                                type="text"
                                                {...register("subject", { required: { value: true, message: data.strapi.technicalSupportPage?.baseForm?.requiredFieldText ?? "" } })}
                                                className={errors.subject ? styles.invalidInputForm : ""}
                                                value={formData.subject ?? ""}
                                                onChange={handleValueChange}
                                            />
                                            <label className={`${styles.inputLabel} ${errors.subject && styles.errorInputFormatColor}`} htmlFor="subject">
                                                {data.strapi.technicalSupportPage?.baseForm?.subjectLabel}
                                            </label>
                                            {errors.subject && <span className={styles.errorInputFormatColor}>{errors.subject.message}</span>}
                                        </div>

                                        <div className={styles.inputGroup}>
                                            <textarea
                                                {...register("description", { required: { value: true, message: data.strapi.technicalSupportPage?.baseForm?.requiredFieldText ?? "" } })}
                                                className={errors.description ? styles.invalidInputForm : ""}
                                                value={formData.description ?? ""}
                                                onChange={handleValueChange}
                                                required
                                            />
                                            <label className={`${styles.inputLabel} ${errors.description && styles.errorInputFormatColor}`} htmlFor="description">
                                                {data.strapi.technicalSupportPage?.baseForm?.descriptionLabel}
                                            </label>
                                            {errors.description && <span className={styles.errorInputFormatColor}>{errors.description.message}</span>}
                                        </div>
                                        <div className={styles.inputFiles}>
                                            <FilesUploader
                                                label={data.strapi.technicalSupportPage?.baseForm?.attachmentsLabel ?? ""}
                                                placeholder={data.strapi.technicalSupportPage?.baseForm?.attachmentsPlaceholder ?? ""}
                                                maxSize={52428800}
                                                maxFiles={maxFiles}
                                                files={files}
                                                onFileAdded={newFiles => setFiles([...files, ...newFiles])}
                                                onRemoveFile={removeFile}
                                                onRemoveAll={() => setFiles([])}
                                                removeAllText={data.strapi.technicalSupportPage?.baseForm?.removeAllFileUploaderField ?? ""}
                                                tooltip={data.strapi.technicalSupportPage?.baseForm?.uploadTooltip?.paragraph ?? ""}
                                            />
                                        </div>
                                    </div>

                                    <div className={styles.terms}>
                                        <span className={styles.checkbox}>
                                            <input type="checkbox" checked={termsAccepted} onChange={e => setTermsAccepted(e.target.checked)} className={styles.checkbox} />
                                            <label>{data.strapi.technicalSupportPage?.baseForm?.acceptTermsLabel}</label>
                                        </span>
                                        <div dangerouslySetInnerHTML={{ __html: data.strapi.technicalSupportPage?.baseForm?.termsTooltip?.paragraph ?? "" }} />
                                    </div>

                                    <Captcha onVerified={token => setFormData({ ...formData, token })} ref={captchaRef} />

                                    <input
                                        type="submit"
                                        value={data.strapi.technicalSupportPage?.baseForm?.submitLabel}
                                        disabled={!termsAccepted || !formData?.token}
                                        className={styles.submitButton}
                                    />
                                </form>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export const query = graphql`
    query TechnicalSupportPage {
        strapi {
            supportTypes {
                id
                name
                product_types {
                    id
                }
                fields {
                    ... on strapi_ComponentSupportSpecificField {
                        id
                        name
                        label
                        tooltip
                    }
                }
            }
            technicalSupportPage {
                base {
                    heading
                    paragraph
                }
                baseForm {
                    emailLabel
                    subjectLabel
                    attachmentsLabel
                    attachmentsPlaceholder
                    descriptionLabel
                    descriptionPlaceholder
                    acceptTermsLabel
                    submitLabel
                    requiredFieldText
                    invalidEmailFieldText
                    removeAllFileUploaderField
                    termsTooltip {
                        heading
                        paragraph
                    }
                    uploadTooltip {
                        heading
                        paragraph
                    }
                }
                notification {
                    successTitle
                    successDescription
                    errorTitle
                    errorDescription
                    acceptButtonText
                    loadingText
                }
                instructions
                typePlaceholder
                productPlaceholder
            }
            products {
                id
                name
                product_type {
                    id
                }
            }
        }
    }
`;
