import { ReactElement } from "react";

interface ISupportTypeSelectProps {
    selectedValue: string;
    onSelectionChange: (supportType: GatsbyTypes.strapi_SupportTypes | undefined) => void;
    types: GatsbyTypes.strapi_SupportTypes[] | undefined;
    placeholder: string;
}

export default function SupportTypeSelect(props: ISupportTypeSelectProps): ReactElement {
    function handleChange(value: string) {
        const suportType = props.types?.find(x => x?.id === value);
        props.onSelectionChange(suportType);
    }

    return (
        <select name="supportType" value={props.selectedValue} onChange={e => handleChange(e.target.value)}>
            <option value="">{props.placeholder}</option>
            {props.types?.map(
                x =>
                    x && (
                        <option key={x.id} value={x.id}>
                            {x.name}
                        </option>
                    )
            )}
        </select>
    );
}
