// extracted by mini-css-extract-plugin
export var technicalSupport = "Technical-module--technicalSupport--Xetor";
export var headerTechnicalSupport = "Technical-module--headerTechnicalSupport--Oh+3l";
export var title = "Technical-module--title--LUDut";
export var headerImage = "Technical-module--headerImage--1juQ0";
export var tooltipContainer = "Technical-module--tooltipContainer--HL88I";
export var attachmentTitleContainer = "Technical-module--attachmentTitleContainer--z872J";
export var infoIcon = "Technical-module--infoIcon--rWXmH";
export var attachmentTitle = "Technical-module--attachmentTitle--nnFRn";
export var attachmentIconTitle = "Technical-module--attachmentIconTitle--smgAV";
export var attachmentDescription = "Technical-module--attachmentDescription--pPbn0";
export var technicalSupportWrapper = "Technical-module--technicalSupportWrapper--s8mXn";
export var inner = "Technical-module--inner--eibBT";
export var instructions = "Technical-module--instructions--jvACu";
export var inputFiles = "Technical-module--inputFiles--eTQ1Q";
export var submitButton = "Technical-module--submitButton--zSGxi";
export var inputGroup = "Technical-module--inputGroup--1aXCP";
export var inputLabel = "Technical-module--inputLabel--bIc7S";
export var mainSelect = "Technical-module--mainSelect--EZcPk";
export var inputsWrapper = "Technical-module--inputsWrapper--U1LbW";
export var inputUpload = "Technical-module--inputUpload--n0J9H";
export var inputDescription = "Technical-module--inputDescription--NWXvQ";
export var terms = "Technical-module--terms--51hhN";
export var checkbox = "Technical-module--checkbox--5QIkK";
export var invalidInputForm = "Technical-module--invalidInputForm--dPpTu";
export var errorInputFormatColor = "Technical-module--errorInputFormatColor--Lrj0o";
export var errorInputFormat = "Technical-module--errorInputFormat--0lPN2";
export var tooltip = "Technical-module--tooltip--qhOcD";
export var termsMobile = "Technical-module--termsMobile--HjqdK";