import { ReactElement, ChangeEvent } from "react";
import { UseFormRegisterReturn } from "react-hook-form";

interface ISupportProductSelectProps {
    selectedValue: string;
    onSelectionChange: (value: string) => void;
    products: GatsbyTypes.strapi_Product[] | undefined;
    supportType: GatsbyTypes.strapi_SupportTypes;
    placeholder: string;
    customClass?: string;
    register: UseFormRegisterReturn;
}

export default function SupportProductSelect(props: ISupportProductSelectProps): ReactElement {
    function getProducts() {
        const productTypes = props.supportType.product_types?.map(y => y?.id);
        return props.products?.filter(x => productTypes?.includes(x?.product_type?.id));
    }

    function handleOnChange(event: ChangeEvent<HTMLSelectElement>) {
        props.onSelectionChange(event.target.value);
        props.register.onChange(event);
    }

    return (
        <select name="supportProduct" className={props.customClass ?? ""} ref={props.register.ref} value={props.selectedValue} onChange={handleOnChange} onBlur={props.register.onBlur}>
            <option value="">{props.placeholder}</option>
            {getProducts()?.map(
                x =>
                    x && (
                        <option key={x.id} value={x.id}>
                            {x.name}
                        </option>
                    )
            )}
        </select>
    );
}
